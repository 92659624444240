
import {Controller} from "@hotwired/stimulus"
import {place_panel} from "./floating_ui_functions.js";
import {pull_content_from_server} from "./functions.js";

export default class extends Controller {

    opening_timer_id = null

    connect() {
        const panel_id = this.element.getAttribute('data-toggle')
        if (!panel_id) {
            console.error(`Attribute data-toggle missing`, this.element)
        }
        const delay_sec = parseFloat(this.element.getAttribute('data-delay'))
        if (!delay_sec) {
            console.error('missing required attribute: data-delay', this.element)
        }
        this.element.addEventListener('mouseenter', (e) => this.start_opening(e, panel_id, delay_sec * 1000))
        this.element.addEventListener('mouseleave', (e) => this.close(e, panel_id))
    }

    start_opening(e, panel_id, delay_ms) {
        let panel = document.getElementById(panel_id)
        pull_content_from_server(panel)
        this.opening_timer_id = setTimeout(() => this.open(e, panel_id), delay_ms)
    }

    open(e, panel_id) {
        this.element.classList.add('tooltip-is-visible')
        this.opening_timer_id = null
        let panel = document.getElementById(panel_id)
        panel.classList.remove('hide');
        place_panel(this.element)
    }

    close(e, panel_id) {
        this.element.classList.remove('tooltip-is-visible')
        if (this.opening_timer_id) {
            clearTimeout(this.opening_timer_id)
        }
        let panel = document.getElementById(panel_id)
        panel.classList.add('hide');
    }

}
