import {Controller} from "@hotwired/stimulus"
import {place_all, find_all_open_elements} from "./floating_ui_functions";
import {debug_log} from "./functions";

export default class extends Controller {

    elements_to_place = null

    connect() {
        const on = this.element.getAttribute('data-on')

        if (on.includes('scroll')) {
            this.element.addEventListener('scroll', () => {
                this.handle_placement()
                debug_log(`place panels by scroll event ${this.element.getAttribute('csedl-place-all-counter')} x: ${this.element.scrollLeft} y: ${this.element.scrollTop}`)
            })
        }

        if (on.includes('resize-observer')) {

            var resize_observer = new ResizeObserver(entries => {
                for (let entry of entries) {
                    this.handle_placement()
                    debug_log(`place panels by resize-observer x: ${this.element.offsetWidth} y: ${this.element.offsetHeight}`)
                }
            });

            resize_observer.observe(this.element)
        }
    }

    handle_placement() {
        if (this.elements_to_place === null) {
            this.elements_to_place = find_all_open_elements(this.element)
        }

        if (this.elements_to_place.length === 0) {
            this.elements_to_place = null
        } else {
            place_all(this.elements_to_place)
            this.element.setAttribute('csedl-place-all-done-time', performance.now())
            const c = Number(this.element.getAttribute('csedl-place-all-counter'))
            this.element.setAttribute('csedl-place-all-counter', c + 1)
            this.setup_run_after_event()
        }
    }

    setup_run_after_event() {
        if (this.element.hasAttribute('data-run-after')) {
            const run_after_str = this.element.getAttribute('data-run-after')
            const run_after = Number(run_after_str)
            if (isNaN(run_after)) {
                console.error(`the data-run-after attribute must be a valid positive number (integer for milliseconds) but is: «${run_after_str}»`, this.element)
            } else if (run_after < 0.0) {
                console.error(`the data-run-after attribute must be a positive number (integer for milliseconds) but is: «${run_after_str}»`, this.element)
            } else {
                if (this.element.getAttribute('csedl-run-after-is-active')) {
                    //debug_log('place-panel run-after is already installed (nothing to do)')
                } else {
                    this.element.setAttribute('csedl-run-after-is-active', true)
                    this.element.setAttribute('csedl-place-all-counter', 0)
                    setTimeout(() => this.handle_placement_after(), 50);
                    debug_log('place-panel run-after INSTALLED')
                }
            }
        }
    }

    handle_placement_after() {
        const run_after = Number(this.element.getAttribute('data-run-after'))
        const last_run = Number(this.element.getAttribute('csedl-place-all-done-time'))
        const c = Number(this.element.getAttribute('csedl-place-all-counter'))
        this.element.setAttribute('csedl-place-all-counter', c + 1)
        const running_time = performance.now() - last_run

        place_all(this.elements_to_place)
        debug_log(`PLACEMENT FOLLOW-UP ${c}: ${running_time}ms / ${run_after}ms`)

        if (running_time < run_after) {
            setTimeout(() => this.handle_placement_after(), 50);

        } else {
            this.element.removeAttribute('csedl-place-all-done-time')
            this.element.removeAttribute('csedl-place-all-counter')
            this.element.removeAttribute('csedl-run-after-is-active')
            this.elements_to_place = null
            debug_log('FINISHED placement follow-up')

        }
    }


}