import {arrow, computePosition, flip, offset, shift} from "@floating-ui/dom";
import {debug_log} from "./functions.js";


export function place_panel(button, log2) {
    const t_id = button.getAttribute('data-toggle')
    let panel = document.getElementById(t_id)
    place(button, panel)
    debug_log('panel placed', log2)
}

export function place_me(panel) {
    let button = document.querySelector(`[data-toggle="${panel.id}"]`)
    place(button, panel)
}

export function find_all_open_elements(scope_element) {
    const buttons = scope_element.getElementsByClassName('has-open-panel')
    const elements = []

    for (const button of buttons) {
        if (button.getAttribute('data-controller') === 'csedl-dropdown') {
            const panel_id = button.getAttribute('data-toggle')
            const panel = document.getElementById(panel_id)
            elements.push([button, panel])
        }
    }

    debug_log(`FOUND ${elements.length} OPEN DROPDOWNS`)

    return (elements)
}

export function place_all(elements) {

    for (const el of elements) {
        place(el[0],el[1])
    }

}

function place(button, panel) {

    let arrow_element = panel.querySelector('#arrow')
    panel.style.removeProperty('height')
    panel.style.removeProperty('left')
    panel.style.removeProperty('top')
    let p = panel.getAttribute('placement')
    let placement = (p ? p : 'bottom')

    computePosition(button, panel, {
        middleware: [flip(), offset(6), shift({padding: 5}), arrow({element: arrow_element})],
        placement: placement

    }).then(({x, y, placement, middlewareData}) => {

        Object.assign(panel.style, {
            left: `${x}px`,
            top: `${y}px`,
        });

        if (arrow_element) {

            //ARROW
            const {x: arrowX, y: arrowY} = middlewareData.arrow;
            const staticSide = {
                top: 'bottom',
                right: 'left',
                bottom: 'top',
                left: 'right',
            }[placement.split('-')[0]];
            Object.assign(arrow_element.style, {
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                right: '',
                bottom: '',
                [staticSide]: '-4px',
            });
            debug_log(`panel + arrow placed`)
        } else {
            debug_log(`panel placed`)
        }

        constrain_to_window_borders(button, panel)
    });
}


function constrain_to_window_borders(button, panel) {
    if (button.getAttribute('data-constrain-to-window-borders') === 'true') {

        const rect = panel.getBoundingClientRect();

        if (rect.top < 0) {
            const new_top = parseFloat(panel.style.top) + Math.abs(rect.top) + 2
            console.log('constrain top', 'actual top:', panel.style.top, 'absolute top:', rect.top, 'new top:', new_top)
            panel.style.top = `${new_top}px`
        }

        const rect2 = panel.getBoundingClientRect();

        if (rect2.bottom > window.innerHeight) {
            console.log('constrain height')
            const h = window.innerHeight - rect2.top - 2
            panel.style.height = `${h}px`
        }


    }
}