window.custom_open_events = function () {
    console.log('custom opening event')
}

const button3 = document.getElementById('button3')
if (button3) {
    button3.addEventListener('before-open-panel', () => {alert('before-open-panel event from button')})
    button3.addEventListener('after-close-panel', () => {alert('after-close-panel event from button')})
}

const panel3 = document.getElementById('panel3')
if (panel3) {
    panel3.addEventListener('before-open', () => {alert('before-open event from panel')})
}