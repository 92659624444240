import { Application } from "@hotwired/stimulus"
window.Stimulus = Application.start()

// initialize dropdowns
import ct from './lib/dropdown-controller'
Stimulus.register('csedl-dropdown', ct)

// initialize tooltips
import tt from './lib/tooltip-controller'
Stimulus.register('csedl-tooltip', tt)

// initialize place-panel controller
import pp from './lib/place-panel-controller'
Stimulus.register('csedl-place-dropdown-panels', pp)